exports.components = {
  "component---src-pages-404-page-js": () => import("./../../../src/pages/404-page.js" /* webpackChunkName: "component---src-pages-404-page-js" */),
  "component---src-pages-about-us-page-js": () => import("./../../../src/pages/about-us-page.js" /* webpackChunkName: "component---src-pages-about-us-page-js" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contact-page.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-home-page-js": () => import("./../../../src/pages/home-page.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-legal-cookie-policy-page-js": () => import("./../../../src/pages/legal/cookie-policy-page.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-page-js" */),
  "component---src-pages-legal-environmental-policy-page-js": () => import("./../../../src/pages/legal/environmental-policy-page.js" /* webpackChunkName: "component---src-pages-legal-environmental-policy-page-js" */),
  "component---src-pages-legal-privacy-policy-page-js": () => import("./../../../src/pages/legal/privacy-policy-page.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-page-js" */),
  "component---src-pages-legal-terms-conditions-page-js": () => import("./../../../src/pages/legal/terms-conditions-page.js" /* webpackChunkName: "component---src-pages-legal-terms-conditions-page-js" */),
  "component---src-pages-our-approach-page-js": () => import("./../../../src/pages/our-approach-page.js" /* webpackChunkName: "component---src-pages-our-approach-page-js" */),
  "component---src-pages-our-data-page-js": () => import("./../../../src/pages/our-data-page.js" /* webpackChunkName: "component---src-pages-our-data-page-js" */),
  "component---src-pages-services-ai-insights-pro-page-js": () => import("./../../../src/pages/services/ai-insights-pro-page.js" /* webpackChunkName: "component---src-pages-services-ai-insights-pro-page-js" */),
  "component---src-pages-services-ai-operational-insights-page-js": () => import("./../../../src/pages/services/ai-operational-insights-page.js" /* webpackChunkName: "component---src-pages-services-ai-operational-insights-page-js" */),
  "component---src-pages-services-customer-acquisition-page-js": () => import("./../../../src/pages/services/customer-acquisition-page.js" /* webpackChunkName: "component---src-pages-services-customer-acquisition-page-js" */),
  "component---src-pages-services-customer-retention-page-js": () => import("./../../../src/pages/services/customer-retention-page.js" /* webpackChunkName: "component---src-pages-services-customer-retention-page-js" */),
  "component---src-pages-services-data-consultancy-page-js": () => import("./../../../src/pages/services/data-consultancy-page.js" /* webpackChunkName: "component---src-pages-services-data-consultancy-page-js" */),
  "component---src-pages-services-data-driven-marketing-page-js": () => import("./../../../src/pages/services/data-driven-marketing-page.js" /* webpackChunkName: "component---src-pages-services-data-driven-marketing-page-js" */),
  "component---src-pages-services-data-healthcheck-page-js": () => import("./../../../src/pages/services/data-healthcheck-page.js" /* webpackChunkName: "component---src-pages-services-data-healthcheck-page-js" */),
  "component---src-pages-services-data-insights-for-decision-makers-page-js": () => import("./../../../src/pages/services/data-insights-for-decision-makers-page.js" /* webpackChunkName: "component---src-pages-services-data-insights-for-decision-makers-page-js" */),
  "component---src-pages-services-data-intelligence-page-js": () => import("./../../../src/pages/services/data-intelligence-page.js" /* webpackChunkName: "component---src-pages-services-data-intelligence-page-js" */),
  "component---src-pages-services-demographic-insights-page-js": () => import("./../../../src/pages/services/demographic-insights-page.js" /* webpackChunkName: "component---src-pages-services-demographic-insights-page-js" */),
  "component---src-pages-services-direct-mail-page-js": () => import("./../../../src/pages/services/direct-mail-page.js" /* webpackChunkName: "component---src-pages-services-direct-mail-page-js" */),
  "component---src-pages-services-high-intent-homemovers-page-js": () => import("./../../../src/pages/services/high-intent-homemovers-page.js" /* webpackChunkName: "component---src-pages-services-high-intent-homemovers-page-js" */),
  "component---src-pages-services-insights-for-campaign-planning-page-js": () => import("./../../../src/pages/services/insights-for-campaign-planning-page.js" /* webpackChunkName: "component---src-pages-services-insights-for-campaign-planning-page-js" */),
  "component---src-pages-services-response-insights-page-js": () => import("./../../../src/pages/services/response-insights-page.js" /* webpackChunkName: "component---src-pages-services-response-insights-page-js" */)
}

